<template>
  <v-container
      fill-height
      fluid
  >
    <v-layout
        justify-center
        align-center
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :isCustomFilter="false"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Self-care Transactions'"
                    :isDetail="false"
                    :isExport="false"
                    :pre-selected-filters="filters.selectedFilters"
        >
          <template slot="filters">
            <filters :filters="filters.timeFilters"></filters>
          </template>
        </data-table>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
  import DataTable from "../components/common/dataTable/DataTable";
  import Filters from "../components/common/dataTable/filters/Filters";
  export default {
    name: "SelfCareTransactions",
    components: {
      Filters,
      DataTable
    },
    data() {
      return {
        loaded: false,
        filters: null,
        apiurl: 'self_care_transactions' ,
        search:{
          placeholder:  'Search by first-name,last-name, Self-Care ID…',
          searchAble: true
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
      }
    },
    created() {
      this.fetchFilters();
    },
    methods: {
      fetchFilters(){
        this.$http.get(this.url+'filters/self_care_transactions').then((res) => {
          if(res.data) {
            this.filters = res.data;
            this.loaded = true;
          } else {
            //
          }
        });
      },
    }

  }
</script>
